import React, { forwardRef, useState, ComponentPropsWithoutRef } from 'react';
import useCloudinaryImage, { Transformation } from './imageHelpers';
import * as namedTransformations from './constants';
import EmptyAvatar from './default_avatar.svg';

export type CloudinaryImageProps = Omit<
    ComponentPropsWithoutRef<'img'>,
    'placeholder' | 'ref'
> & {
    publicId?: string;
    transformation?: Transformation;
    placeholderUrl?: string;
    alt?: string;
    className?: string;
    options?: object;
    shouldAutoFormat?: boolean;
    // FIXME: Temp solution while we still have cloudinary stuff on DS
    cloudinaryEnv?: {
        hostName: string;
        cloudName: string;
    };
};

const CloudinaryImage = forwardRef<HTMLImageElement, CloudinaryImageProps>(
    (
        {
            publicId = '',
            transformation = namedTransformations.FILM_RATIO_SMALL,
            placeholderUrl = '',
            alt,
            className = '',
            options = {},
            shouldAutoFormat = true,
            cloudinaryEnv,
            ...rest
        },
        ref
    ) => {
        const placeholder = (placeholderUrl || EmptyAvatar) as string;
        const [hasError, setHasError] = useState(false);
        const src = useCloudinaryImage(
            publicId,
            transformation,
            options,
            cloudinaryEnv,
            shouldAutoFormat
        );

        return (
            <img
                {...rest}
                ref={ref}
                className={`${className} rounded-[10px]`}
                src={!hasError ? src : placeholder}
                alt={alt || ''}
                loading="lazy"
                onLoadStart={() => {
                    setHasError(false);
                }}
                onErrorCapture={() => {
                    setHasError(true);
                }}
            />
        );
    }
);

export default CloudinaryImage;
