import { useMemo } from 'react';

interface CloudinaryOptions {
    placeholder?: string;
    crop?: string;
    width?: string;
    height?: string;
    format?: string;
    quality?: string;
    gravity?: string;
    named?: string;
}

export interface Transformation {
    name: string;
    width?: number;
    height?: number;
}

type CloudinaryImageLoaderProps = {
    publicId: string;
    options?: Record<string, string | string[]>;
    // FIXME: Temp solution while we still have cloudinary stuff on DS
    cloudinaryEnv?: {
        hostName: string;
        cloudName: string;
    };
};

function getImagePublicId(photoPublicId: string): string {
    const autoUploadDirectory = 'remote_media';

    return RegExp(`${autoUploadDirectory}`).test(`${photoPublicId}`)
        ? `${photoPublicId}`
        : `${autoUploadDirectory}/${photoPublicId}`;
}

const cloudinaryTransformations: Record<string, string> = {
    crop: 'c',
    width: 'w',
    height: 'h',
    format: 'f',
    quality: 'q',
    gravity: 'g',
    named: 't',
};

const cloudinaryParseOption = (
    option: string,
    value: string | string[]
): string => {
    const transformation = cloudinaryTransformations[option];

    if (!transformation) {
        throw new TypeError(`Invalid cloudinary transformation: "${option}".`);
    }

    if (Array.isArray(value)) {
        return `${transformation}_${value[0]}:${value[1]}`;
    }

    return `${transformation}_${value}`;
};

const cloudinaryImageLoader = ({
    publicId,
    options = {
        placeholder: '',
    },
    //FIXME: this is a temporary solution while we have cloudinary stuff in design-system
    // on web we uses the environment variables so the design-system know which url to use
    // here is an example: web/src/components/CommentCard/components/Reply/Reply.js
    cloudinaryEnv = {
        hostName: 'https://res.cloudinary.com',
        cloudName: 'trustedhousesitters-development',
    },
}: CloudinaryImageLoaderProps): string => {
    const { hostName, cloudName } = cloudinaryEnv;

    const transformations = Object.entries(options)
        .filter(([, value]) => !!value)
        .reduce(
            (acc: string[], [key, value]: [string, string | string[]]) => [
                ...acc,
                cloudinaryParseOption(key, value),
            ],
            [] as string[]
        )
        .join(',');

    const baseUrl = `${hostName}/${cloudName}/image/upload`;
    const imagePublicId = getImagePublicId(publicId);

    if (!transformations) {
        return `${baseUrl}/v1/${imagePublicId}`;
    }

    return `${baseUrl}/${transformations}/v1/${imagePublicId}`;
};

function useCloudinaryImage(
    publicId: string,
    transformation: Transformation,
    options: CloudinaryOptions = {
        placeholder: '',
    },
    // FIXME: Temp solution while we still have cloudinary stuff on DS
    cloudinaryEnv?: {
        hostName: string;
        cloudName: string;
    },
    shouldAutoFormat?: boolean
): string {
    const format = shouldAutoFormat ? { format: 'auto' } : {};
    const src = useMemo(() => {
        if (!publicId) {
            return options.placeholder || '';
        }
        const { ...cloudinaryOptions } = options;

        return cloudinaryImageLoader({
            publicId,
            options: {
                named: transformation.name,
                ...format,
                ...cloudinaryOptions,
            },
            cloudinaryEnv,
        });
    }, [publicId, transformation, options]);

    return src;
}

export default useCloudinaryImage;
